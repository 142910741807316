import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CTABand from '../components/cta-band/cta-band';
import ButtonBanner from '../components/button-banner/button-banner';
import DefaultLayout from '../components/default-layout/default-layout';
import Hero from '../components/hero/hero';
import HomePageGrid from '../components/home-page-grid/home-page-grid';
import SEO from '../components/seo/seo';
import UpcomingEvents from '../components/upcoming-events/upcoming-events';
import Promo from '../components/promo/promo';
import { useCurrentFilter } from '../hooks/use-current-filter';
import { filterExpiredNodes } from '../helpers/hooks-helpers';
import HomepageInfoBanner from '../components/homepage-info-banner/homepage-info-banner';

const IndexPage = ({ data }) => {
  const upcomingEvents = useCurrentFilter(data.UpcomingEvents.edges, filterExpiredNodes);
  
  return (
    <DefaultLayout data={data}>
      <SEO
        title=""
        description="The heart of downtown Sandy Springs, GA."
        siteConfig={data.site.siteMetadata}
        pageUrl="/"
      />
      <Hero data={data} />
      <HomepageInfoBanner
        infoId={'095f6806-a92b-41ba-9737-d00afaca22eb'}
        promotionName={'CitySprings Homepage: Banner #1 Buttons'}
        titleColor={'#1c5f8a'}
        paragraphColor={'#1C5F8A'}
        buttonStyles={'buttonWrapper1'}
        useBackgroundImage={false}
      />
      <Promo
        tid={260}
        content={data.HomepagePromo.relationships.field_promoted_content}
      />
      <HomepageInfoBanner
        infoId={'7a38e44e-79a5-4031-863f-86c2853733a6'}
        promotionName={'CitySprings Homepage: Banner #2 Buttons'}
        titleColor={'#fff'}
        paragraphColor={'#fff'}
        buttonStyles={'buttonWrapper2'}
        useBackgroundImage={true}
      />
      <HomePageGrid />
      <HomepageInfoBanner
        infoId={'0dfa28cf-5969-4174-979c-11bb4d4ce549'}
        promotionName={'CitySprings Homepage: Banner #3 Buttons'}
        titleColor={'#1c5f8a'}
        paragraphColor={'#1C5F8A'}
        buttonStyles={'buttonWrapper1'}
        useBackgroundImage={false}
      />
      <Promo
        tid={261}
        content={data.HomepageSecondaryPromo.relationships.field_promoted_content}
      />
      {/* <ButtonBanner
        titleTid={263} // to be updated
        buttonTid={264}
      /> */}
      {/* 2025 Upcoming Events removed from page */}
      {/* <UpcomingEvents data={upcomingEvents.slice(0, 4)} hideXL={true} /> */}
    </DefaultLayout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query IndexQuery {
    HomepagePromo: taxonomyTermPromotion(
      drupal_id: { eq: "2b32703e-cc38-41ae-b348-a718a6ed2cd0" }
    ) {
      ...Promoted
    }
    HomepageSecondaryPromo: taxonomyTermPromotion(
      drupal_id: { eq: "d632b311-aff2-4962-9016-b1c2d1d82aa4" }
    ) {
      ...Promoted
    }
    HomepageHero: taxonomyTermPromotion(
      drupal_id: { eq: "894a0848-1de1-4c35-9d00-e3ca691cfe0f" }
    ) {
      ...Promoted
    }
    UpcomingEvents: allNodeEvent(
      sort: { fields: [field_date___value], order: ASC }
      filter: { field_private_event: { ne: true } }
    ) {
      edges {
        node {
          ...UpcomingEvents
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export default IndexPage;

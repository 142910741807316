import { useStaticQuery, graphql } from 'gatsby';

const useHomepageBannerPromotionsData = (promotionName) => {
  // Query grabs all of the Promotions but will filter out the one that is given in the promotionName parameter.
  const { allTaxonomyTermPromotion } = useStaticQuery(
    graphql`
      query PromotionsMenuData {
        allTaxonomyTermPromotion {
          edges {
            node {
              id
              name
              relationships {
                node__information {
                  hyperlink {
                    options {
                      attributes {
                        target
                      }
                    }
                    title
                    uri
                  }
                  field_order
                }
              }
            }
          }
        }
      }
    `
  );

  // Process the data
  return (
    allTaxonomyTermPromotion.edges
      ?.map((edge) => edge.node)
      .filter((node) => node.name === promotionName) // Filter by promotion name
      .map((node) => ({
        ...node,
        relationships: {
          ...node.relationships,
          node__information: Array.isArray(node.relationships.node__information)
            ? [...node.relationships.node__information].sort(
                (a, b) => (a.field_order ?? 0) - (b.field_order ?? 0) // Sort by field_order, default to 0 if undefined
              )
            : [], // Default to an empty array if node__information is null or not an array
        },
      })) ?? []
  );
};

export default useHomepageBannerPromotionsData;
import React from 'react';
import { Markup } from 'react-render-markup';

import { useInformationData } from '../../hooks/use-information-data';
import usePromotionData from '../../hooks/use-homepage-banner-promotions-data';

import Button from '../button/button';

import styles from './homepage-info-banner.module.scss';

const HomepageInfoBanner = ({ infoId, promotionName, buttonStyles, useBackgroundImage, ...props }) => {
  const buttonInformation = usePromotionData(promotionName);
  const thisInformation = useInformationData().filter((item) => item.id === infoId) || [];
  if (!thisInformation || !thisInformation.length) {
    return null;
  }
  return (
    <section
  className={useBackgroundImage ? styles.buttonBanner : ''}
  style={!useBackgroundImage ? { backgroundColor: thisInformation[0].backgroundColor } : {}}
>

      <div className={styles.bannerWrapper}>
        <h2 className={styles.title} style={{ color: props.titleColor }}>
          {thisInformation?.[0]?.title}
        </h2>
        <div
          style={{
            color: props.paragraphColor,
            lineHeight: "1.25em",
          }}
        >
          <Markup markup={thisInformation?.[0]?.description} />
        </div>
        {/* Dynamically set the button wrapper class */}
        <div className={`${styles[buttonStyles]}`}>
          {buttonInformation && buttonInformation.length > 0 &&
            buttonInformation[0].relationships.node__information?.map((item, index) => (
              <Button
                {...props}
                key={index}
                className={styles.button}
                color="white"
                textStyle="upper"
                to={item.hyperlink?.uri}
                target={item.hyperlink.options.attributes.target}
                style={{
                  color: props.ctaColor,
                  backgroundColor: props.ctaBackgroundColor,
                }}
              >
                {item.hyperlink.title}
              </Button>
            ))}
        </div>
      </div>
    </section>
  );
};

export default HomepageInfoBanner;
